$card-padding: $spacer-5;

// use caution when using of any class listed below this line, they might be slated for deprecation

ul.card-content-metadata {
	color: $text-subtle;
	font-size: $font-size-extra-small;
	line-height: $font-size-small;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: 0;
	margin-inline: 0 !important;
	padding: 0;

	> li {
		list-style: none;
		display: flex;
		flex-basis: auto;
		margin-bottom: $spacing-1;

		&:not(:only-child):not(:last-child) {
			margin-inline-end: 0.75rem;
		}

		&:not(:only-child):not(:first-child) {
			padding-inline-end: 0.75rem;
		}

		&:first-of-type,
		&:only-of-type {
			margin-inline-start: 0;
		}

		&:last-of-type {
			@include discouraged-mobile {
				margin-inline-start: 0;
			}
		}
	}

	&.has-bullets {
		.docon {
			font-size: 13px;
			line-height: inherit;
			margin-inline-end: 5px;
			width: 14px;
		}

		> li:not(:last-of-type):not(:only-of-type) {
			&:after {
				padding-inline-start: 5px;
				padding-inline-end: 5px;
				content: '\2022'/ '';
			}

			&.has-text-danger {
				@include deprecate();

				&:after {
					color: $text-subtle;
				}
			}
		}
	}
}

.card-footer {
	&.is-left,
	&.is-right {
		.card-footer-item {
			flex-grow: 0;
		}
	}

	&.is-left {
		justify-content: flex-start;
	}

	&.is-right {
		justify-content: flex-end;
	}

	.card-footer-item {
		> .buttons {
			margin-block: -0.375rem;

			> .button {
				margin-block-end: 0;
			}
		}

		> .progress,
		> .progress-bar {
			margin-block: 0.375rem;
			max-width: 5rem;
		}

		> .progress + .progress-label,
		> .progress-bar + .progress-label {
			font-size: $font-size-9;
			color: $text-subtle;
			padding-inline-start: 0.5rem;
		}

		> .progress-label {
			font-size: $font-size-9;
			color: $success;
			text-transform: uppercase;

			.docon {
				padding-inline-start: 0.25rem;
			}
		}

		&:first-child,
		&:only-child {
			justify-content: flex-start;
		}

		&:last-child {
			justify-content: flex-end;
		}

		&:not(:last-child):not(:first-child):not(:only-child) {
			justify-content: center;
		}
	}
}

.card-tag {
	position: absolute;
	top: $card-padding;
	right: $card-padding + 0.5rem;

	// xp-tags are the worst. Sincerely, The person who wrote .xp-tag
	.xp-tag {
		min-width: inherit;
		font-size: $font-size-extra-small;
		top: 0 !important;
		right: 0 !important;
	}
}

.card {
	.card-content-title {
		flex-grow: 1;
		font-weight: $weight-semibold;
		line-height: 1.25;
		font-size: $font-size-body;
		text-align: start;
		@include truncated(3);

		&:first-child {
			margin-top: none;
		}

		&:only-child {
			margin: 0;
		}
	}

	.card-content-supertitle {
		@include truncated(1);

		letter-spacing: $letter-spacing-wide;
		line-height: 1;
		text-transform: uppercase;
		font-size: $font-size-extra-small;
	}

	.card-content-super-title {
		letter-spacing: $letter-spacing-wide;
		line-height: 1;
		text-transform: uppercase;
		font-size: $font-size-extra-small;
	}

	@include tablet {
		&.is-horizontal {
			flex-direction: row;
			flex-wrap: wrap;

			.card-content {
				padding-inline-start: 0;
				max-width: calc(100% - 7rem);
				padding-inline-end: $spacing-6 + 2rem;
			}

			.card-footer {
				width: 100%;
				flex-shrink: 0;
				flex-grow: 1;
			}
		}
	}
}

$card-branded-spacing: $spacer-5;
$card-branded-header-padding: 1.75rem;

.card.is-branded {
	border-top-left-radius: $border-radius-lg;
	border-top-right-radius: $border-radius-lg;
	border-color: transparent;
	border: 1px solid $border-white-high-contrast;

	& > :first-child {
		border-top-right-radius: $border-radius-lg;
		border-top-left-radius: $border-radius-lg;
	}

	.card-header {
		margin-top: -26px;
		margin-left: -1px;
		margin-right: -1px;
		border-bottom: 1px solid $border-white-high-contrast;

		&:first-child {
			padding-top: 0;
		}
	}

	.card-header-image {
		top: $card-branded-header-padding;
	}

	.card-content {
		margin-top: $card-branded-spacing;

		.card-content-title {
			margin-bottom: $card-branded-spacing;
		}
	}

	.card-tag {
		position: absolute;
		top: $card-branded-spacing;
		right: 0;

		.xp-tag-hexagon {
			margin-right: -1px;

			&:after {
				content: '';
				#{$content-right}: 0;
				background-color: transparent;
				border-#{$content-left}: 0;
				border-#{$content-right}: 0;
				border-bottom: 0;
				border-top: 0;
			}
		}
	}
}

.assessment-cards {
	grid-template-columns: 1fr;

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}

	@include desktop {
		grid-template-columns: repeat(3, 1fr);
	}

	&.grid-columns-4 {
		grid-template-columns: repeat(4, 1fr);
	}

	.card {
		height: 100%;
	}
}

.session-cards {
	display: flex;

	.scroll-snap-item {
		@include tablet {
			min-width: 450px;
			max-width: 450px;
		}
	}
}
