/*
 * Intial variables
 * This file used to be the place to define tokens, such as breakpoints and intial spacing values.
 * However, now tokens should be defined in Atlas. This file contains values that should be deleted when we're able to do so.
 */

// Non-Bulma breakpoint for added mobile flexibility, see mixins.
$mobile-lg: 480px;

// Direction - can delete when docs-ui styles all use logical properties

$content-text-direction: ltr !default;
$content-left: if($content-text-direction == rtl, right, left);
$content-right: if($content-text-direction == rtl, left, right);
$content-translate-x-direction: if($content-text-direction == rtl, -1, 1);

// Spacing related sizes

$spacing-0: 0 !default; // 0
$spacing-1: 0.25rem !default; // 4px
$spacing-2: 0.5rem !default; // 8px
$spacing-3: 1rem !default; // 16px
$spacing-4: 1.5rem !default; // 24px
$spacing-5: 3rem !default; // 48px
$spacing-6: 6rem !default; // 96px;
