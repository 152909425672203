// $ignite-banner-background-color: #1023d7;

/**
 * Temporary styles for 2024 Ignite banner
 * https://ceapex.visualstudio.com/Engineering/_workitems/edit/990830/
 * Always use absolute path for the image URL
 */
.build-2025-banner {
	background-color: #2c1b23;
	color: $white-static;
	outline-color: $white-static;
	background-repeat: no-repeat;
	background-image: url('https://learn.microsoft.com/en-us/media/event-banners/banner-build-2025.jpg');
	background-size: auto 100%;
	background-position-y: center;
	background-position-x: clamp(-350px, 25vw - 350px, -125px);

	body[dir='rtl'] & {
		transform: scaleX(-1);

		.uhf-container {
			transform: scaleX(-1);
		}

		button[data-dismiss] {
			inset-inline-start: 0;
		}
	}

	@include tablet {
		background-position-x: right;
	}
}

.ai-skills-fest-2025-banner {
	background-color: #1b1a20;
	color: $white-static;
	outline-color: $white-static;
	background-repeat: no-repeat;
	background-image: url('https://learn.microsoft.com/en-us/media/event-banners/banner-ai-skills-fest-2025.jpg');
	background-size: auto 100%;
	background-position-y: center;
	background-position-x: clamp(-350px, 25vw - 450px, -125px);

	body[dir='rtl'] & {
		transform: scaleX(-1);

		.uhf-container {
			transform: scaleX(-1);
		}

		button[data-dismiss] {
			inset-inline-start: 0;
		}
	}

	@include tablet {
		background-position-x: right;
	}
}
