.c-progress {
	min-height: 50px;
}

progress.c-progress[value] {
	appearance: none;
	border: 0;
	min-width: 296px;
	height: 4px;
}

progress.c-progress::-webkit-progress-bar {
	background-color: rgba(0, 0, 0, 0.2);
}

progress.c-progress::-webkit-progress-value {
	background-color: $primary;
}

progress.c-progress::-moz-progress-bar {
	background-color: $primary;
}

progress.c-progress > [role='progressbar'] {
	display: block;
	position: relative;
	background-color: rgba(0, 0, 0, 0.2);
	min-width: 296px;
	width: 100%;
	height: 4px;
}

progress.c-progress > [role='progressbar'] > span {
	display: block;
	position: relative;
	height: 100%;
	background-color: $primary;
	color: $primary;
}

.c-progress {
	display: block;
	margin-top: 12px;
}
@media screen and (-ms-high-contrast) {
	.c-progress.f-indeterminate-local span:after,
	.c-progress.f-indeterminate-regional span:after {
		background-color: $body-background;
	}
}
@media screen and (-ms-high-contrast: black-on-white) {
	.c-progress.f-indeterminate-local span:after,
	.c-progress.f-indeterminate-regional span:after {
		background-color: $text;
	}
}

.c-progress.f-indeterminate-regional {
	position: relative;
	min-width: 296px;
	width: 100%;
}

.c-progress.f-indeterminate-regional span {
	position: absolute;
	display: inline-block;
	opacity: 0;
	animation: dots 4s infinite;
}

.c-progress.f-indeterminate-regional span:after {
	content: '';
	position: absolute;
	display: inline-block;
	background-color: $primary;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}

.c-progress.f-indeterminate-regional span:nth-child(1) {
	animation-delay: 50ms;
}

.c-progress.f-indeterminate-regional span:nth-child(1):after {
	left: -10px;
}

.c-progress.f-indeterminate-regional span:nth-child(2) {
	animation-delay: 0.1s;
}

.c-progress.f-indeterminate-regional span:nth-child(2):after {
	left: -20px;
}

.c-progress.f-indeterminate-regional span:nth-child(3) {
	animation-delay: 0.15s;
}

.c-progress.f-indeterminate-regional span:nth-child(3):after {
	left: -30px;
}

.c-progress.f-indeterminate-regional span:nth-child(4) {
	animation-delay: 0.2s;
}

.c-progress.f-indeterminate-regional span:nth-child(4):after {
	left: -40px;
}

.c-progress.f-indeterminate-regional span:nth-child(5) {
	animation-delay: 0.25s;
}

.c-progress.f-indeterminate-regional span:nth-child(5):after {
	left: -50px;
}

.c-progress.f-indeterminate-local {
	position: relative;
}

.c-progress.f-indeterminate-local span {
	position: absolute;
	transform: rotate(225deg);
	animation: orbit 5.5s infinite;
}

.c-progress.f-indeterminate-local span:after {
	content: '';
	position: absolute;
	border-radius: 100%;
	background-color: $primary;
}

.c-progress.f-indeterminate-local span:nth-child(2) {
	animation-delay: 240ms;
}

.c-progress.f-indeterminate-local span:nth-child(3) {
	animation-delay: 480ms;
}

.c-progress.f-indeterminate-local span:nth-child(4) {
	animation-delay: 720ms;
}

.c-progress.f-indeterminate-local span:nth-child(5) {
	animation-delay: 960ms;
}

.c-progress.f-indeterminate-local.f-progress-large,
.c-progress.f-indeterminate-local.f-progress-large span {
	width: 60px;
	height: 60px;
}

.c-progress.f-indeterminate-local.f-progress-large span:after {
	width: 6px;
	height: 6px;
}

.c-progress.f-indeterminate-local.f-progress-small {
	width: 20px;
	height: 20px;
}

.c-progress.f-indeterminate-local.f-progress-small span {
	width: 25px;
	height: 25px;
}

.c-progress.f-indeterminate-local.f-progress-small span:after {
	width: 3px;
	height: 3px;
}
