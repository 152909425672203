a.lightbox-enabled {
	position: relative;
	text-decoration: none !important; /* !important for IE 11 */

	&:hover,
	&:focus-visible {
		cursor: zoom-in;

		&:after {
			background-color: $body-background;
			color: $text;
		}
	}

	&:after {
		content: get-icon-code('inspect');
		position: absolute;
		display: flex;
		height: 40px;
		width: 40px;
		bottom: 20px;
		#{$content-right}: 10px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $secondary-box-shadow;
		color: $text-subtle;
		font-family: 'docons';
		font-size: 20px;
		text-decoration: none !important;
	}
}

/*
 * Fixing the double appearance of the magnifying icon
 * on complex images with long descriptions and lightbox activated
 */
div.visually-hidden + a.lightbox-enabled {
	display: none;
}

.image-browser {
	overflow: hidden;

	div {
		overflow: hidden;
		border: 1px solid transparent;
	}

	img {
		display: block;
		margin: 0 auto;
		touch-action: none;
		transition: opacity 0.5s ease-in 0.3s;
	}
}

.image-browser-expandable {
	cursor: zoom-in;
	max-height: 75vh;
	max-width: 95vw;

	@include tablet {
		max-height: 80vh;
		max-width: 80vw;
	}
}

.image-browser-pannable {
	cursor: move;
	max-width: none;
}

.image-browser-transition {
	transition: all 0.3s linear;
}
