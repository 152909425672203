.infographic {
	display: none;

	@include desktop {
		display: grid;
		grid-template-columns: 1fr 165px 1fr 165px 1fr;
		grid-template-rows: auto 165px auto;
		justify-content: center;
		align-items: center;

		.media {
			flex-direction: column;
		}
	}

	@include widescreen {
		width: 88%;

		.media {
			flex-direction: row;
		}
	}
}
