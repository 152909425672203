// not greenfielded yet

.content {
	img {
		border: 0;
	}

	img,
	video {
		max-width: 100%;
		height: auto;
	}

	img,
	audio,
	canvas,
	progress,
	video {
		display: inline-block;
		vertical-align: baseline;
	}

	audio:not([controls]) {
		display: none;
		height: 0;
	}

	svg:not(:root) {
		overflow: hidden;
	}

	& ul ul {
		margin: 0;
		margin-#{$content-left}: 20px;
	}

	& ul ul > li {
		list-style-type: circle;
	}

	& ul ul ul > li {
		list-style-type: square;
	}

	& ol ol {
		margin: 0;
		margin-#{$content-left}: 20px;
	}

	& ol ol > li {
		list-style-type: lower-alpha;
	}

	& ol ol ol > li {
		list-style-type: lower-roman;
	}

	h1:first-of-type {
		margin-top: -10px;
		margin-bottom: 0;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.3;
		margin-bottom: 12px;
		margin-top: 10px;
	}

	h1,
	h2.likeAnH1 {
		line-height: 1.3;
		margin-bottom: 12px;
		@include responsive-font-size($font-size-1);
		margin-top: 0;
		word-wrap: break-word; // browser support fallback, because FF and IE
		word-break: break-word;
	}

	h2 {
		@include responsive-font-size($font-size-2);
		margin-top: 32px;
	}

	h3 {
		@include responsive-font-size($font-size-3);
		margin-top: 30px;
		margin-bottom: 18px;
	}

	h4 {
		@include responsive-font-size($font-size-4);
		margin-top: 36px;
		margin-bottom: 6px;
	}

	h5 {
		@include responsive-font-size($font-size-5, false, 1.1rem);
		letter-spacing: 1px;
		margin-top: 36px;
		margin-bottom: 6px;
	}

	h6 {
		@include responsive-font-size($font-size-6, false, 1.05rem);
		letter-spacing: 1px;
		margin-top: 36px;
		margin-bottom: 6px;
	}

	p {
		margin-top: 1rem;
		margin-bottom: 0;
		word-wrap: break-word;
	}

	blockquote {
		display: block;
		margin: 1.2em 0 2em;
		padding: 0 0.8em;
		border-width: 0 0 0 3px;
		border-style: solid;
		border-color: $border;
	}

	blockquote footer {
		font-size: 0.9em;
		color: $text-subtle;
	}

	ul.clean,
	ol.clean {
		margin: 0;
		padding: 0;
		margin-bottom: 16px;
	}

	ul,
	ol {
		margin: 16px 0;
		margin-#{$content-left}: 38px;
	}

	ul > li,
	ol > li {
		list-style: disc outside none;
	}

	ul > li > ul > li {
		list-style-type: circle;
	}

	ol > li {
		list-style-type: decimal;
	}

	$checklist-marker-size: 1em;
	$checklist-ul-spacing: 1rem;

	/**** checklist list ****/

	.checklist > ul {
		margin-#{$content-left}: $checklist-ul-spacing;

		> li {
			list-style-type: none;
			position: relative;
			margin-#{$content-left}: $checklist-marker-size;

			&:before {
				position: absolute;
				#{$content-left}: -$checklist-marker-size * 1.5;
				font-family: 'docons';
				content: get-icon-code('check');
				display: inline-block;
				height: $checklist-marker-size;
				width: $checklist-marker-size;
				color: $success;
			}
		}
	}

	table {
		margin-top: 1rem;
	}

	td > ul:first-child {
		margin-top: 0;
	}

	&[lang^='zh'],
	&[lang^='ja'],
	&[lang^='ko'] {
		th {
			// Prevents line breaks at each character in multibyte languages (happens only in Chrome)
			white-space: nowrap;
		}
	}

	nobr {
		white-space: normal;
	}

	a:not([class]),
	a[class=''],
	a:focus-visible {
		@include external-indicator;
	}

	hr {
		margin-block: 1.5rem;
		color: $text-subtle;
	}
}
