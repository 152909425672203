.doc-outline {
	h3 {
		font-size: 1rem;
		margin: 0;
		margin-top: 16px;

		@include desktop {
			margin-top: 0;
		}

		&:not(.right-rail-card-heading):last-child {
			display: none;
		}
	}

	h2:not(.right-rail-card-heading):last-child {
		display: none;
	}

	ol {
		margin: 10px 0 0;
		list-style-type: none;

		li {
			list-style: none;
		}
	}

	li {
		line-height: 1.3;
		font-size: 0.875rem;
		padding: 2px 0;
		padding-#{$user-left}: 7px;
		margin: 4px 0;
	}

	li.selected {
		text-shadow: 0.3px 0 0 currentColor; // use text-shadow rather than font-weight to avoid reflow as items are activated
		border-color: $primary;
		padding-#{$user-left}: 4px;
		border-#{$user-left}: 3px solid $primary;
	}

	a {
		&:visited {
			color: $primary;
		}
	}
}
