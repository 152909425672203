.metadata.operation-id {
	margin-top: -12px;
}

//REST templates

.example-block {
	margin-top: 16px;

	h4 {
		margin: 0;
		padding: 8px 0 0;
	}

	.metadata {
		padding: 0;
	}
}
