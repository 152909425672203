.media {
	align-items: flex-start;
	display: flex;

	.content:not(:last-child) {
		margin-bottom: 0.75rem;
	}

	&.is-user-dir .media-left {
		margin-#{$content-right}: 0;
		margin-#{$user-right}: 1rem;
	}

	&.is-user-dir .media-right {
		margin-#{$content-left}: 0;
		margin-#{$user-left}: 1rem;
	}

	.media {
		border-top: 1px solid $box-shadow-color-medium;
		display: flex;
		padding-top: 0.75rem;

		.content:not(:last-child),
		.control:not(:last-child) {
			margin-bottom: 0.5rem;
		}

		.media {
			padding-top: 0.5rem;

			& + .media {
				margin-top: 0.5rem;
			}
		}
	}

	& + .media {
		border-top: 1px solid $box-shadow-color-medium;
		margin-top: 1rem;
		padding-top: 1rem;
	}

	// Sizes

	&.is-large {
		& + .media {
			margin-top: 1.5rem;
			padding-top: 1.5rem;
		}
	}
}

.media-left,
.media-right {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
}

.media-left {
	margin-#{$content-right}: 1rem;
}

.media-right {
	margin-#{$content-left}: 1rem;
}

.media-content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;
	word-break: break-word;
}
