$half-width-spacing: 1.5rem;

.container {
	margin: 0 auto;
	position: relative;
	width: auto;

	&.is-fluid {
		margin-left: $container-gap;
		margin-right: $container-gap;
		max-width: none;
	}

	&.is-max-mobile {
		max-width: $mobile-lg;
	}

	&.is-max-tablet {
		@include tablet {
			max-width: $breakpoint-tablet;
		}
	}

	&.is-max-desktop {
		@include desktop {
			max-width: $breakpoint-desktop - 2 * $container-gap;
		}
	}

	@include desktop {
		max-width: $breakpoint-desktop - 2 * $container-gap;
	}

	@include until-widescreen {
		&.is-widescreen {
			max-width: $breakpoint-widescreen - 2 * $container-gap;
		}
	}

	@include widescreen {
		max-width: $breakpoint-widescreen - 2 * $container-gap;
	}
}
