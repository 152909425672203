.heading-wrapper {
	position: relative;

	&:not(:hover) .anchor-link:not(:focus-visible) {
		@include visually-hidden();
		opacity: 0;
	}

	& .anchor-link {
		font-size: 1rem;
		position: absolute;
		transition: opacity 0.1s linear;
		transform: translateY(-50%) scale(0.75);
		width: $column-gap;

		inset-inline-start: calc(-#{$column-gap} * 1.25);

		@include tablet {
			transform: translateY(-50%) scale(1);
			width: $large-column-gap;
			inset-inline-start: calc(#{$large-column-gap} * -1.25);
		}

		&:hover {
			text-decoration: none;
		}
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.3;

		padding-inline-start: calc(#{$column-gap} * 1.25);
		margin-inline-start: calc(-#{$column-gap} * 1.25);

		@include tablet {
			padding-inline-start: calc(#{$large-column-gap} * 1.25);
			margin-inline-start: calc(-#{$large-column-gap} * 1.25);
		}
	}
}

@each $heading,
	$size
		in (
			'h2': $font-size-2,
			'h3': $font-size-3,
			'h4': $font-size-4,
			'h5': $font-size-5,
			'h6': $font-size-6
		)
{
	[data-heading-level='#{$heading}'] .anchor-link {
		inset-block-start: calc(#{$size} * 1.3 / 2);
	}
}
