@each $name, $color-set in $colors {
	$base: nth($color-set, $effect-index-base);

	.border-left-l-#{$name} {
		border-#{$user-left}: $border-width-lg solid $base !important;
	}
}

.compensate-border-width {
	margin: $border-width !important;
}
