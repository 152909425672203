// Remove after uhf cleanup
// created to fix the breadcrumbs weird padding between `uhf-tablet` and `desktop` screen sizes
@include tablet {
	.has-padding-left-none-uhf-tablet {
		padding-inline-start: $spacing-0 !important;
	}
}
// remove end

.margin-top-negative-sm {
	margin-block-start: -$layout-3 !important;
}

.margin-top-negative-md {
	margin-block-start: -$layout-6 !important;
}

.margin-left-negative-xxs {
	margin-inline-start: -$layout-1 !important;
}

// avoid using if possible, only used in profile achievement certificate
.padding-top-none-mobile {
	@include discouraged-mobile {
		padding-top: $layout-0 !important;
	}
}

// -content- margin and padding should be used in content - for horizontal helpers only
.has-content-margin-left-none {
	margin-inline-start: $layout-0 !important;
}

.has-content-margin-right-none {
	margin-inline-end: $layout-0 !important;
}

.has-content-margin-right-xxs {
	margin-inline-end: $layout-1 !important;
}

.has-content-margin-right-xs {
	margin-inline-end: $layout-2 !important;
}

.has-content-margin-left-sm {
	margin-inline-start: $layout-3 !important;
}

@include tablet {
	.has-content-margin-left-none-tablet {
		margin-inline-start: $layout-0 !important;
	}

	.has-content-margin-right-none-tablet {
		margin-inline-end: $layout-0 !important;
	}

	.has-content-padding-right-none-tablet {
		padding-inline-end: $layout-0 !important;
	}

	.has-content-margin-right-xxl-tablet {
		margin-inline-end: $layout-7 !important;
	}
}
