$table-cell-narrow-width: 15%;
$table-cell-extra-narrow-width: 5%;

.table {
	td,
	th {
		p:first-child {
			margin-top: 0;
		}

		&.is-narrow {
			width: $table-cell-narrow-width;
		}
		&.is-narrow-desktop-only {
			width: $table-cell-narrow-width;
			@include discouraged-mobile {
				width: 100%;
			}
		}

		&.is-extra-narrow {
			width: $table-cell-extra-narrow-width;
			@include discouraged-mobile {
				width: 100%;
			}
		}
	}

	// Modifiers
	&.has-row-header-background {
		tbody td,
		tbody th {
			&:first-child {
				background-color: $table-row-header;
			}
		}
	}

	&.table-fixed-layout {
		@include tablet {
			table-layout: fixed;
		}
	}

	&.table-tabular-responsive {
		@include discouraged-mobile {
			thead {
				border: none;
				clip: rect(0 0 0 0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;
			}

			tr {
				display: block;
			}

			th {
				display: block;
			}

			td {
				display: block;
			}

			td::after {
				/*
				* aria-label has no advantage, it won't be read inside a table
				content: attr(aria-label);
				*/
				content: attr(data-label);
			}
		}
	}
}
