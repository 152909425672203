$quote-spacing: 0.0675rem !default;
$quote-font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif !default;
$quote-color: $primary !default;
$open-quote-font-size: 10rem !default;

$close-quote-font-size: 4rem !default;

%quote {
	display: inline-block;
	font-family: $quote-font-family;
	color: $quote-color;
	font-size: $open-quote-font-size;
	line-height: 0.1rem;
	margin-right: $quote-spacing;
	vertical-align: -1em * 0.4;
}

.open-quote:after {
	@extend %quote;
	content: open-quote;
}

.close-quote:after {
	@extend %quote;
	opacity: 0.8;
	content: close-quote;
	font-size: $close-quote-font-size;
	margin-right: 0;
	margin-left: $quote-spacing;
	vertical-align: -1em * 0.4;
}
