.checkbox-toggle-container {
	flex-direction: row;
	align-items: center;
	padding: 4px 8px;
	color: $text;

	> label {
		padding-#{$user-right}: 8px;
	}
}

.checkbox-toggle-wrapper {
	margin: 0 0 0 8px;
	display: flex;

	label.switch {
		order: 1;
		@media screen and (min-width: 1195px) {
			order: 3;
		}
		margin-#{$user-left}: 1px;
		background-color: $body-background;
		cursor: pointer;
		display: block;
		border: 1px solid $text;
		border-radius: 12px;
		width: 44px;
		height: 16px;
		transition: 0.2s ease-in;

		&:after {
			transition: 0.4s cubic-bezier(0.01, 1.23, 0.58, 0.96);
			border-radius: 50%;
			transform: translate(3px, 2.5px);
			body[dir='rtl'] & {
				transform: translate(-3px, 2.5px);
			}
			width: 8px;
			height: 8px;
			border: 1px solid $body-background;
			background-color: $text;
			display: block;
			content: '';
		}
	}

	input[type='checkbox']:checked {
		& + label.switch {
			background-color: $primary;
			border: 0.125rem solid $primary;

			&:after {
				background-color: $body-background;
				transform: translate(31px, 2px);
				body[dir='rtl'] & {
					transform: translate(-31px, 2px);
				}
			}
		}
	}

	input[type='checkbox']:disabled {
		& + label.switch {
			border-color: currentColor;
			background-color: $button-disabled-background-color;
			opacity: $button-disabled-opacity;
			box-shadow: $button-disabled-shadow;
			cursor: not-allowed;
		}
	}

	input[type='checkbox'] {
		position: absolute;
		pointer-events: none;
		opacity: 0;
		height: 0;
		width: 0;
		order: 2;

		&:focus-visible + label.switch {
			@extend %focus;
		}
	}
}
