/* Remove when/if this added to Atlas */
.chat-container {
	display: grid;
	// height: 100vh; // leaving as a warning, height value depends on whether .chat-container a full height container, a sticky one, or a constrained one.
	grid-template: auto minmax(0, 1fr) auto / minmax(0, 1fr);
	grid-template-areas:
		'chat-header'
		'chat-body'
		'chat-form';

	.chat-container-header {
		grid-area: chat-header;
		border-block-end: 1px solid $border-white-high-contrast;
	}

	.chat-container-body {
		grid-area: chat-body;
		scrollbar-gutter: stable;
		gap: $spacer-4;

		@extend .scroll-vertical;
	}

	.chat-container-form {
		grid-area: chat-form;

		border-block-start: 1px solid $border-white-high-contrast;
		position: sticky;
		inset-block-end: 0;
	}
}

// some styling required to get layouts to work based which containers/layout its in

.layout.layout-twin.layout-constrained {
	.layout-body-flyout {
		overflow: hidden !important;
	}
}

.layout.layout-single .layout-body-flyout,
.layout.layout-holy-grail .layout-body-flyout,
.layout.layout-sidecar-right .layout-body-flyout,
.layout.layout-sidecar-left .layout-body-flyout {
	.chat-container-body {
		margin-bottom: var(--atlas-header-visible-height);
	}
}

// heights

.layout-single .chat-container,
.layout-holy-grail .chat-container,
.layout.layout-sidecar-right .chat-container,
.layout.layout-sidecar-left .chat-container {
	height: 100vh;
}

.layout-twin .chat-container {
	height: 100%;
}

.modal .chat-container {
	height: 100vh;
}

// need a better long term solution for this

.layout-single.layout-flyout-active .uhf-container:not(.has-padding) {
	@include desktop {
		align-self: center;
		max-width: $breakpoint-desktop - 2 * $container-gap; // from .modular-content-container
	}
}
