%uhf-container-margin {
	margin-right: auto;
	margin-left: auto;
	max-width: calc(100% - #{(2 * $layout-gap)});
	width: calc(100% - #{(2 * $layout-gap)});

	@include widescreen {
		margin-right: auto;
		margin-left: auto;
		max-width: $layout-widescreen-width;
		width: $layout-widescreen-width;
	}
}

%uhf-container {
	&:not(.has-padding) {
		@extend %uhf-container-margin;
		@include desktop {
			&.is-fluid {
				margin-left: $layout-gap;
				margin-right: $layout-gap;
				max-width: none;
				width: auto;
			}
		}
	}

	&.has-padding,
	&.is-banner {
		box-sizing: border-box;
		width: 100%;
		margin-right: 0;
		margin-left: 0;

		@include discouraged-mobile {
			> * {
				padding-right: $layout-gap;
				padding-left: $layout-gap;
			}
		}

		@include tablet {
			padding-right: $layout-gap;
			padding-left: $layout-gap;
		}

		@include widescreen {
			padding-left: $layout-widescreen-gap;
			padding-right: $layout-widescreen-gap;
		}
	}

	// easily overwrite a uhf container to be full width using this class

	&.is-full {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
		max-width: 100%;
	}

	&.has-top-padding {
		padding-top: $spacing-4;
	}
}

.uhf-container {
	@extend %uhf-container;
}

%uhf-container-padding {
	padding-left: $layout-gap;
	padding-right: $layout-gap;

	@include widescreen {
		padding-left: $layout-widescreen-gap;
		padding-right: $layout-widescreen-gap;
	}
}

.has-uhf-padding {
	@extend %uhf-container-padding;
}
