$arrow-color: var(--arrow-color, #243a5e);
$arrow-height: var(--arrow-height, 90%);
$arrow-width: var(--arrow-width, 90%);
$arrow-border-transparent: 30px solid transparent;
$arrow-border-colored: 30px solid $arrow-color;
$arrow-shaft: 20px solid $arrow-color;
$arrow-rotation-right: if($user-text-direction == 'ltr', 90deg, 270deg);
$arrow-rotation-left: if($user-text-direction == 'ltr', 270deg, 90deg);

.arrow {
	height: $arrow-height;
	width: 100%;
	position: relative;
	z-index: 100;

	&::after {
		content: '';
		width: 0;
		height: 0;
		border-inline-start: $arrow-border-transparent;
		border-inline-end: $arrow-border-transparent;
		border-block-end: $arrow-border-colored;
		position: absolute;
	}

	&.arrow-up {
		inset-inline-start: calc(50% - 10px);
		inset-block-end: -8px;
		border-inline-start: $arrow-shaft;
		&::after {
			inset-block-start: -25px;
			inset-inline-start: -39px;
		}
	}
	&.arrow-right {
		inset-block-end: calc(-50% + 10px);
		width: $arrow-width;
		border-block-start: $arrow-shaft;
		&::after {
			transform: rotate($arrow-rotation-right);
			inset-inline-end: -38px;
			inset-block-start: -24px;
		}
	}
	&.arrow-down {
		inset-block-end: 8px;
		inset-inline-start: calc(-50% + 10px);
		border-inline-end: $arrow-shaft;
		&::after {
			transform: rotate(180deg);
			inset-block-end: -25px;
			inset-inline-end: -39px;
		}
	}
	&.arrow-left {
		inset-block-start: calc(-50% + 10px);
		inset-inline-end: -10%;
		width: $arrow-width;
		border-block-end: $arrow-shaft;
		&::after {
			transform: rotate($arrow-rotation-left);
			inset-inline-start: -38px;
			inset-block-end: -24px;
		}
	}
}
