.is-fixed-bottom {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: $zindex-fixed;
}

.is-full-height {
	height: 100%;
}

.is-full-height-mobile {
	@include discouraged-mobile {
		height: 100%;
	}
}

.has-height-zero {
	height: 0 !important;
}

.has-height-auto {
	height: auto !important;
}

.fly-modal-text-ellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fly-modal-content {
	overflow-y: auto !important;
}

.new-article-published-url {
	line-break: anywhere;
}

.updated-toc-container {
	height: 25rem;
	overflow-y: auto;
}

.toc-node-title {
	flex: 6;
}

.toc-popover {
	align-self: center;
}

.article-position-container {
	margin-right: 0.2rem;

	&,
	.article-move-up,
	.article-move-down {
		flex: 1;
	}

	.icon-move-up,
	.icon-move-down {
		position: relative;
		bottom: 0.1rem;
	}
}

.tree-item.is-leaf.has-icon {
	.article-position-container {
		.icon-move-up,
		.icon-move-down {
			&:hover {
				text-decoration: none !important;
			}
		}
	}
}

.learn-editor-help {
	position: relative;
	bottom: 2rem;
}

// list

.is-unstyled {
	list-style-type: none !important;
}

//Shorten texts with an ellipsis. The maximum width
//of the truncated text can be changed by overriding
//the max-width.
//Loosely modeled after https://github.com/primer/primer/blob/master/modules/primer-truncate/lib/truncate.scss

.is-inline-truncated {
	display: inline-block;
	max-width: 125px;
	@include has-overflow-ellipsis;

	&.has-max-width-200 {
		max-width: 200px;
	}
}

.max-width-150 {
	max-width: 150px !important;
}

.is-vertically-scrollable {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.has-line-height-reset {
	line-height: 1.3 !important;
}

.has-outline-hover:hover {
	outline: 1px solid $primary;
}

.has-heavy-shadow-hover:hover {
	box-shadow: $box-shadow-heavy !important;
	transition: all 300ms cubic-bezier(0.8, 0, 0.2, 1);
}

.is-text-centered {
	text-align: center;
}

.is-text-centered-tablet {
	@include tablet {
		text-align: center;
	}
}

.is-text-right {
	text-align: right;
}

.is-text-right-tablet {
	@include tablet {
		text-align: right;
	}
}

.is-full-width {
	width: 100% !important;
}

.is-full-width-mobile {
	@include discouraged-mobile {
		width: 100% !important;
	}
}

.is-one-third-width {
	width: 33% !important;
}

@include tablet {
	.is-one-third-width-tablet {
		width: 33% !important;
	}
}

@include desktop {
	.is-one-quarter-width-desktop {
		width: 25% !important;
	}
}

.has-two-lines-height {
	min-height: 2.5em;
}

.has-four-lines-height {
	min-height: 5em;
}

.has-min-height-256 {
	min-height: 256px;
}

.has-max-height-eight-lines {
	max-height: 10em;
}

.has-max-height-twelve-lines-tablet {
	@include tablet {
		max-height: 15em;
	}
}

.has-max-height-260 {
	max-height: 260px;
}

.has-max-height-300 {
	max-height: 300px;
}

.delete {
	@include delete;
}

.expanded-indicator {
	transition: transform 0.15s;

	[aria-expanded='true'] &,
	details[open] & {
		transform: rotateX(180deg);
	}
}

.is-radiusless {
	border-radius: 0 !important;
}

.has-overflow-hidden {
	overflow: hidden !important;
}

.has-overflow-scroll {
	overflow: scroll !important;
}

.is-comma-delimited {
	> :not(:last-child):after {
		content: ', ';
	}
}

.is-dot-separated:not(:last-of-type):not(:only-of-type):after {
	padding-#{$user-left}: 5px;
	padding-#{$user-right}: 5px;
	content: '•';
}

.has-z-index-one {
	z-index: 1;
}

.has-min-height-567-tablet {
	@include tablet {
		min-height: 567px;
	}
}

.has-two-text-columns {
	@include tablet {
		columns: 2;
	}
}

.has-three-text-columns {
	@include tablet {
		columns: 3;
	}
}

.has-three-text-columns-list-items {
	break-inside: avoid;
}

.has-fluid-text-columns {
	@include tablet {
		columns: 3;
	}

	@include widescreen {
		columns: 5;
	}
}

.has-appearance-none {
	appearance: none;
}

.has-cursor-default {
	cursor: default !important;
}

.has-background-linethrough {
	position: relative;
	z-index: 1;
}

.has-background-linethrough:before {
	border-top: $border-width solid $border;
	content: '';
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	z-index: -1;
}

.is-scaled-marketing-image {
	transform: scale(1.6) translate(0%, 23%);

	@include desktop {
		transform: scale(2.2) translate(20%, 17%);
	}
}

.is-downward-translated-marketing-image {
	box-shadow: $box-shadow-extra-heavy;
	transform-origin: left center;
	transform: translateY(35%);

	@include desktop {
		transform: translateY(50%) scale(1.2);
	}
}

.has-backdrop-filter-blur {
	backdrop-filter: blur(2px);
}

.has-text-hyphens {
	hyphens: auto;
}

.previous-sibling-overlay-l-desktop {
	@include desktop {
		margin-top: -7rem;
	}
}

.previous-sibling-overlay-m-tablet {
	@include tablet {
		margin-top: -5rem;
	}
}

.opacity-none {
	opacity: 0;
}

[dir='rtl'] .flip-rtl {
	transform: scaleX(-1);
}

// 4em is just an eyeball guess. Could be 3em?
.table-of-contents {
	min-height: 4.5em;
}

.admin-toc-tree {
	max-height: 60rem;
	overflow-y: auto;
	padding-right: 0.5rem;
}

.metadata-container {
	border-width: 1px;
	border-style: solid;
	border-color: var(--theme-control-border);
}

.contribution-files-dropdown {
	appearance: auto;

	&:hover {
		cursor: pointer;
	}
}

.timer {
	gap: 2px;
	grid-template-columns: minmax(min-content, auto) repeat(2, min-content auto);
	box-shadow: inset 0 0 4px $box-shadow-color-extra-heavy;
}

// Assessment Guidance Sorting Dropdown options
html.assessments {
	.sorting-dropdown {
		.option {
			height: 10px;
			width: 10px;
			min-height: 10px;

			&.is-danger {
				background-color: var(--theme-danger-base);
				color: var(--theme-danger-invert);
			}
			&.is-warning {
				background-color: var(--theme-warning-base);
				color: var(--theme-warning-invert);
			}
			&.is-success {
				background-color: var(--theme-success-base);
				color: var(--theme-success-invert);
			}
		}
	}
}

/*
* Some elements change frequently or load inconsistently, such as the date in an article or icons in radio buttons.
* Because of this, the can cause false positive visual diffs.
* We can use .visibility-hidden-visual-diff to hide an item so that it does not cause false positives.
* Be careful when doing this, be sure
* 1) you can't fix the diff another way, and
* 2) the item in question is fairly well established. i.e. you're confident hiding this won't mean we miss a bug.
*/
html.visual-diff .visibility-hidden-visual-diff {
	visibility: hidden !important;
}

/*
 * When this class is applied, do not show the element at all on the page.
 * This is different from .visibility-hidden-visual-diff in that it will not take up space on the page.
 */
html.visual-diff .display-none-visual-diff {
	display: none !important;
}

// Background image custom CSS variable classes

/*
* Sets the path to a static image as the CSS variable value. Use together with Atlas's
* .background-image-pattern atomic to add a background pattern image. Portable to different domains and
* allows for longer caching via hashed file names.
*/
.background-image-pattern-plus {
	--background-image-pattern: url('/packages/styles/images/background-image-pattern-plus.svg');
}

.background-image-pattern-pixie-sticks {
	--background-image-pattern: url('/packages/styles/images/background-image-pattern-pixie-sticks.svg');
}

// Site header adjustments
.site-header {
	// External indicator on header's buttons
	.site-header-button.has-external-link-indicator {
		@include external-indicator-flex;
	}

	// Fixing visaul alignment of the chevron icon
	.site-header-button {
		.icon.expanded-indicator {
			margin-block-start: 0.25em;
		}
	}

	// Hotfix, move to Atlas
	.site-header-button,
	.site-header-brand {
		&:hover,
		&:visited {
			color: $site-header-control-color;
		}
	}
}

// temporary styles, move them to Atlas
.overflow-x-hidden {
	overflow-x: hidden;
}

.height-full {
	height: 100%;
}

.height-80vh-tablet {
	@include tablet {
		height: 80vh !important;
	}
}

.first-p-top-margin-reset {
	p:first-child {
		margin-block-start: 0;
	}
}
