.stretched-link {
	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: $zindex-stretched-link;
		content: '';
	}
}
