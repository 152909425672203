html.episode,
html.eventsession {
	.vjs-poster img {
		width: auto;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
	}

	.amp-default-skin .vjs-control-bar {
		bottom: -35px !important;
	}

	.vjs-fullscreen .vjs-control-bar {
		bottom: 0 !important;
	}

	.vjs-fullscreen .vjs-tech {
		height: 95% !important;
	}

	#video-holder {
		width: 100%;
		height: 0;
		padding-bottom: 56.25% !important;
		position: relative;
	}

	#video-element,
	video[data-shaka-player] {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 0 !important;
	}

	.shaka-video-container,
	.lazy-load-container {
		position: absolute;
		width: 100%;
	}
}
