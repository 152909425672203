// unclear on whether we need this, seems cool, isn't much code

.level {
	@include block;

	align-items: center;
	justify-content: space-between;

	img {
		display: inline-block;
		vertical-align: top;
	}

	// Modifiers

	&.is-mobile {
		display: flex;

		.level-left,
		.level-right {
			display: flex;
		}

		.level-left + .level-right {
			margin-top: 0;
		}

		.level-item {
			&:not(:last-child) {
				margin-bottom: 0;
			}

			&:not(.is-narrow) {
				flex-grow: 1;
			}

			margin-right: 0.75rem;
		}
	}

	@include tablet {
		display: flex;

		& > .level-item {
			&:not(.is-narrow) {
				flex-grow: 1;
			}
		}
	}
}

.level-item {
	align-items: center;
	display: flex;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: center;

	.title,
	.subtitle {
		margin-bottom: 0;
	}

	@include discouraged-mobile {
		&:not(:last-child) {
			margin-bottom: 0.75rem;
		}
	}
}

.level-left,
.level-right {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;

	.level-item {
		// Modifiers

		&.is-flexible {
			flex-grow: 1;
		}

		@include tablet {
			&:not(:last-child) {
				margin-right: 0.75rem;
			}
		}
	}
}

.level-left {
	align-items: center;
	justify-content: flex-start;

	@include discouraged-mobile {
		& + .level-right {
			margin-top: 1.5rem;
		}
	}

	@include tablet {
		display: flex;
	}
}

.level-right {
	align-items: center;
	justify-content: flex-end;

	@include tablet {
		display: flex;
	}
}
