// Deprecated hero styles - please ignore and avoid using anything from below
$hero-image-tablet-max-height: 325px; // deprecated
$hero-image-desktop-max-height: 500px; // deprecated
$hero-mobile-overlap-margin: 220px; // deprecated

.hero {
	@each $name, $color-set in $colors {
		$base: nth($color-set, $effect-index-base);
		$invert: nth($color-set, $effect-index-invert);

		&.is-#{$name} {
			// @include deprecate(); // coming soon
			background-color: $base;
			color: $invert;
			outline-color: $invert;

			a:not(.button):not(.dropdown-item):not(.tag),
			strong {
				color: inherit;
			}

			.title,
			.subtitle {
				color: $invert;
			}

			a:not(.button),
			strong {
				color: $invert;
			}
		}
	}

	// .hero-body,
	// .container {
	// 	@include deprecate(); // coming soon
	// }
	// Sizes

	&.is-small .hero-body {
		// @include deprecate(); // coming soon
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
	}

	&.is-medium {
		// @include deprecate(); // coming soon
		@include tablet {
			.hero-body {
				padding-bottom: 9rem;
				padding-top: 9rem;
			}
		}
	}

	&.is-large {
		// @include deprecate(); // coming soon
		@include tablet {
			.hero-body {
				padding-bottom: 18rem;
				padding-top: 18rem;
			}
		}
	}

	&.is-halfheight,
	&.is-fullheight .hero-body {
		// @include deprecate(); // coming soon
		align-items: center;
		display: flex;

		& > .container {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	&.is-halfheight {
		// @include deprecate(); // coming soon

		min-height: 50vh;
	}

	&.is-fullheight {
		// @include deprecate(); // coming soon
		min-height: 100vh;
	}

	&.has-overlap-bottom-mobile {
		// @include deprecate(); // coming soon
		@include discouraged-mobile {
			padding-bottom: $hero-mobile-overlap-margin;
			margin-bottom: -$hero-mobile-overlap-margin;
		}
	}

	.hero-head,
	.hero-foot {
		// @include deprecate(); // coming soon
		flex-grow: 0;
		flex-shrink: 0;
	}

	.hero-body {
		// @include deprecate(); // coming soon
		flex-grow: 1;
		flex-shrink: 0;
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-inline: 0;

		@include widescreen {
			padding-inline: $layout-widescreen-gap;
		}

		z-index: 1;
		.is-light {
			color: $white-static;
			@include desktop {
				html.theme-light & {
					color: $text;
				}
				html.theme-high-contrast & {
					color: $white-static;
				}
				html.theme-dark & {
					color: $white-static;
				}
			}
		}
	}
	.hero-card {
		&.is-dark {
			// @include deprecate(); // coming soon
			color: $white-static;
			outline-color: $text-invert;

			@include desktop {
				color: $primary-invert;
				background-color: $secondary-dark;
			}
		}

		&.is-light {
			// @include deprecate(); // coming soon
			color: $white-static;
			outline-color: $text-invert;
			@include desktop {
				html.theme-light & {
					background-color: $secondary-background;
					color: $text;
				}
				html.theme-high-contrast & {
					background-color: $body-background;
					color: $white-static;
				}
				html.theme-dark & {
					background-color: $body-background;
					color: $white-static;
				}
			}
		}
	}

	.object-fit-fill {
		// @include deprecate(); // coming soon
		object-fit: fill !important;
	}

	#hero-body-container {
		// @include deprecate(); // coming soon
		html.theme-light & {
			@include desktop {
				color: $text;
			}
			color: $text-invert;
		}
	}

	.hero-background-image {
		// @include deprecate(); // coming soon
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		overflow: hidden;
		width: 100%;

		@include until($breakpoint-desktop) {
			bottom: 0;
		}

		@include discouraged-mobile-lg {
			max-height: $hero-image-tablet-max-height;
		}

		@include desktop {
			max-height: $hero-image-desktop-max-height;
		}

		img {
			width: auto;
			height: 100%;
			max-height: 100%;
			max-width: none;

			@include desktop {
				width: 100%;
			}

			@include widescreen {
				margin-top: -5%;
			}
		}

		svg {
			width: 100%;
			height: 100%;
			max-height: 100%;
			max-width: none;
		}
	}

	& .is-full-max-height {
		// @include deprecate(); // coming soon
		max-height: 100% !important;
	}
}
