// Typography
$font-size-jumbo: $font-size-0 !default;
$font-size-large: $font-size-6 !default;
$font-size-body: $font-size-7 !default;
$font-size-small: $font-size-8 !default;
$font-size-extra-small: $font-size-9 !default;

$font-heading-sizes: $font-size-1 $font-size-2 $font-size-3 $font-size-4 $font-size-5 $font-size-6 !default;

$code-block-size: $font-size-small !default;

$normal-font-stack: Segoe UI, SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Color indexes, for use in color loops.

$effect-index-base: 1;
$effect-index-background: 2;
$effect-index-dark: 3;
$effect-index-hover: 4;
$effect-index-active: 5;
$effect-index-invert: 6;
$effect-index-box-shadow: 7;
$effect-index-background-glow-high-contrast: 8;

// Color indexes for brand

$brand-index-base: 1;
$brand-index-card: 2;
$brand-index-invert: 3;
