$section-padding: 3rem !default;
$section-padding-small: 1.5rem !default;
$section-padding-medium: 9rem !default;
$section-padding-large: 18rem !default;
$section-padding-horizontal: 1.5rem !default;
// greenfield
// how does it work? https://bulma.io/documentation/layout/section/

.section {
	padding: $section-padding $section-padding-horizontal;

	// Responsiveness
	@include desktop {
		// Sizes

		&.is-uniform {
			padding: $section-padding;
		}

		&.is-small {
			padding: $section-padding-small $section-padding-horizontal;

			&.is-uniform {
				padding: $section-padding-small;
			}
		}

		&.is-medium {
			padding: $section-padding-medium $section-padding-horizontal;

			&.is-uniform {
				padding: $section-padding-medium;
			}
		}

		&.is-large {
			padding: $section-padding-large $section-padding-horizontal;

			&.is-uniform {
				padding: $section-padding-large;
			}
		}
	}
}
