/**
 * Responsive 1, 2, 3 and 4 column layout
 */

.cols {
	box-sizing: border-box;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	> * {
		box-sizing: content-box;
		display: block;

		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: 100%;

		list-style: none;

		margin-top: 24px;
	}

	&.noSpace > * {
		margin-top: 0;
	}

	@media only screen and (min-width: 925px) {
		&.cols2 > *,
		&.cols3 > *,
		&.cols4 > * {
			flex-basis: calc(50% - 24px / 2);
		}

		&.cols3.discrete > * {
			flex-basis: 100%;
		}
	}

	@media only screen and (min-width: 1024px) {
		&.cols3 > *,
		&.cols3.discrete > * {
			flex-basis: calc(33.33333% - 24px * 2 / 3);
		}

		&.cols3 > *:last-child:nth-child(3n - 4) {
			margin-right: calc(33.33333% + 24px * 1 / 3);
		}

		&.cols4 > *:last-child:nth-child(4n - 6) {
			margin-right: calc(50% + 24px * 1 / 2);
		}

		&.cols4 > *:last-child:nth-child(4n - 5) {
			margin-right: calc(25% + 24px * 1 / 4);
		}
	}

	@media only screen and (min-width: 1280px) {
		&.cols4 > * {
			flex-basis: calc(25% - 24px * 3 / 4);
		}
	}
}

//  MarkDig Markdown extension row and row > columns

.content {
	.row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 12px;

		@media only screen and (min-width: 925px) {
			flex-wrap: nowrap;
		}

		> .column {
			display: block;
			box-sizing: border-box;
			flex: 0 1 100%;
			width: 100%;
			overflow-x: auto;

			a:not([class]) {
				@include external-link-selection {
					display: none !important;
				}
			}

			& > * {
				max-width: 100%;
				margin-right: 0;
				margin-left: 0;
				width: 100%;
				box-sizing: border-box;
			}

			.codeHeader,
			pre,
			pre > code {
				box-sizing: border-box;
				max-width: 100%;
				margin: 0;
			}

			ul,
			ol {
				// overriding default ul/ol styles again.
				box-sizing: border-box;
				margin: 0;
				margin-#{$content-left}: 38px;
				max-width: calc(100% - 38px);

				li {
					word-wrap: break-word;
					word-break: break-word;
				}
			}

			@media only screen and (min-width: 925px) {
				flex-basis: calc(25% - 8px);
				flex-grow: 1;
				flex-shrink: 1;
				margin-right: 12px;

				&:last-child {
					margin-right: 0;
				}

				&.span2 {
					width: 50%;
					flex-grow: 2;
					flex-shrink: 1;
					flex-basis: 50%;
				}

				&.span3 {
					flex-shrink: 1;
					flex-grow: 3;
					flex-basis: 75%;
				}
			}
		}
	}
}
