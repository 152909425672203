@use 'sass:math';

$nav-bar-height: 54px !default;
$nav-bar-font-size-mobile: $font-size-small !default;
$nav-bar-font-size-desktop: $font-size-body !default;
$nav-bar-horizontal-spacing: $spacing-2 !default;

$nav-bar-button-color: $text !default;
$nav-bar-button-hover-background-color: $body-background-medium !default;
$nav-bar-button-hover-color: $text !default;
$nav-bar-button-active-background-color: $body-background-medium !default;

$nav-bar-button-tag-margin: math.div($font-size-extra-small, 3) !default;

$nav-bar-chevron-font-size: $font-size-extra-small !default;
$nav-bar-chevron-color: $text-subtle !default;

$nav-bar-hover-bottom-thickness: math.div($spacing-1, 2) !default;
$nav-bar-border-bottom-style-hover: 2px solid $nav-bar-button-hover-color !default;

$nav-bar-submenu-button-padding: $spacing-2 $spacing-3 !default;

.nav-bar {
	position: relative;
	display: flex;
	align-items: stretch;
	height: $nav-bar-height;
	min-height: $nav-bar-height;
	justify-content: space-between;
	padding-#{$user-left}: 0;
	padding-#{$user-right}: 0;

	box-sizing: border-box;
	width: 100%;

	@include tablet {
		padding-inline: $layout-gap;
		padding-left: $layout-gap;
	}

	@include widescreen {
		padding-#{$user-left}: $layout-widescreen-gap;
		padding-#{$user-right}: $layout-widescreen-gap;
	}

	.dropdown-menu {
		background-color: $nav-bar-button-hover-background-color;

		.nav-bar-button {
			&.dropdown-trigger {
				width: 100%;
			}

			&:hover,
			&:focus-visible {
				background-color: $nav-bar-button-active-background-color;
				text-decoration: underline !important;
				width: 100%;
			}
		}
	}
}

.nav-bar-nav {
	display: none;
	@include tablet {
		display: flex;
	}
	align-items: stretch;
}

.nav-bar-nav-list {
	display: flex;
	align-items: stretch;
}

.nav-bar-item {
	display: flex;
	align-items: center;
	flex-shrink: 0;

	&.has-spacing {
		margin: 0 $nav-bar-horizontal-spacing;
	}

	&.is-category {
		margin: 0 $nav-bar-horizontal-spacing;

		> .nav-bar-button {
			.tag {
				margin-bottom: $nav-bar-button-tag-margin;
			}
		}
		> .is-title {
			padding-left: $nav-bar-horizontal-spacing;
		}

		&:before {
			content: '';
			display: inline-block;
			height: $spacing-4;
			border-left: 2px solid $text-subtle;
			margin-#{$user-left}: $nav-bar-horizontal-spacing;
			margin-#{$user-right}: 2 * $nav-bar-horizontal-spacing;
		}
	}
}

.nav-bar-button {
	display: flex;
	align-items: center;
	justify-content: center;

	align-self: stretch;
	flex-shrink: 0;
	flex-grow: 0;

	min-width: 54px;
	padding: 0 $nav-bar-horizontal-spacing;

	background-color: transparent; // override button default
	color: $nav-bar-button-color;
	border: none; // override button default

	text-decoration: none !important;
	white-space: nowrap;
	font-size: $nav-bar-font-size-mobile;
	line-height: normal;
	cursor: pointer;
	@include unselectable;
	outline-offset: -1 * $focus-width !important; // inset focus outline.

	.dropdown-menu {
		justify-content: flex-start;
	}

	&:active,
	&.is-active,
	&:hover,
	&:focus-visible {
		color: $nav-bar-button-hover-color;

		&.has-hover-underline:not([aria-expanded='true']) {
			> :first-child {
				position: relative;

				&:after {
					content: '';
					display: block;
					position: absolute;
					border-bottom: $nav-bar-border-bottom-style-hover;
					left: 0;
					right: 0;
					bottom: -#{$nav-bar-hover-bottom-thickness};
				}
			}
		}
	}

	&:visited {
		color: $nav-bar-button-color;
	}

	&.dropdown-trigger[aria-expanded='true'] {
		background-color: $nav-bar-button-hover-background-color;
	}

	.dropdown-menu & {
		padding: $nav-bar-submenu-button-padding;
	}

	&.is-title {
		font-size: $nav-bar-font-size-desktop;
		word-break: break-word;
		padding: 0;
		background-color: $body-background;
		line-height: normal;

		@include tablet {
			font-size: $font-size-6;
			font-weight: 600;
			line-height: 1.125;
			margin: 0;
			padding: 0 $nav-bar-horizontal-spacing;
		}
	}

	.tag {
		margin-bottom: $nav-bar-button-tag-margin;
		vertical-align: middle;
		font-weight: inherit;
	}

	&.has-external-link-indicator {
		@include external-indicator-flex;
	}
}

.nav-bar-button-chevron {
	margin-#{$user-left}: auto;
	padding-#{$user-left}: math.div($nav-bar-horizontal-spacing, 2);
	font-size: $nav-bar-chevron-font-size;
	color: $nav-bar-chevron-color;

	.dropdown-menu & {
		padding-#{$user-left}: $nav-bar-horizontal-spacing * 2;
	}

	.docon {
		vertical-align: sub;
	}
}

// a sentinel... when it has shrunk to zero width we know we need
// to collapse items into the "more" menu.
.nav-bar-spacer {
	display: none;

	@include tablet {
		display: block;
	}
	flex-grow: 1;
	flex-shrink: 1;
}

.nav-bar-logo {
	width: 108px;
	height: 23px;
}
