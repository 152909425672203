.is-size-jumbo-responsive {
	@include responsive-font-size($font-size-jumbo, true);
}

// Heading sizes

.is-size-h1-responsive {
	@include responsive-font-size($font-size-1, true);
}

.is-size-h2-responsive {
	@include responsive-font-size($font-size-2, true);
}

.is-size-h3-responsive {
	@include responsive-font-size($font-size-3, true);
}

.is-size-h4-responsive {
	@include responsive-font-size($font-size-4, true);
}

// Wrapping

.has-text-wrap {
	word-wrap: break-word !important;
	white-space: normal !important;
	word-break: break-word !important;
	height: auto !important;
}

%has-text-wrap {
	overflow: hidden;
	word-wrap: break-word;
	white-space: normal;
	word-break: break-word;
}

.has-no-wrap {
	white-space: nowrap;
}

.has-text-overflow-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

// Case

.is-capitalized {
	text-transform: capitalize !important;
}

.is-lowercase {
	text-transform: lowercase !important;
}

.is-uppercase {
	text-transform: uppercase !important;
}

.is-underlined-dotted {
	border-bottom: 1px dotted;
}

.is-normal {
	font-style: normal;
}

.has-text-subtle {
	color: $text-subtle !important;
}

.has-text-overlay-invert {
	color: $overlay-invert;
}

.has-overflow-ellipsis {
	@include has-overflow-ellipsis;
}

.has-overflow-ellipsis-tablet {
	@include tablet {
		@include has-overflow-ellipsis;
	}
}

// Alignment

.has-text-centered {
	text-align: center !important;
}

.has-text-centered-mobile {
	@include discouraged-mobile {
		text-align: center !important;
	}
}

.has-text-centered-tablet {
	@include tablet {
		text-align: center !important;
	}
}

.has-text-justified {
	text-align: justify !important;
}

// Remove after references are replaced with text-align-left
.has-text-left {
	@include deprecate();
	text-align: left !important;
}

.has-text-right {
	text-align: right !important;
}

.text-align-right-desktop {
	@include desktop {
		text-align: $user-right !important;
	}
}

.has-tabular-nums {
	font-variant-numeric: tabular-nums;
}
