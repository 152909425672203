%thumb-rating-icons {
	.docon-dislike:before {
		content: get-icon-code('dislike-solid');
	}
	.docon-like:before {
		content: get-icon-code('like-solid');
	}
}

.thumb-rating,
.thumb-rating-button {
	.icon {
		color: $primary;
	}

	&:hover,
	&.is-selected,
	&.button-selected {
		@extend %thumb-rating-icons;

		&.button-filled {
			.icon {
				color: $body-background;
			}
		}
	}
}
