// :( this covers large mobile & small desktop widths when grid has 2 columns
$grid-is-3-tablet-min-width: 628px;
$grid-is-3-tablet-max-width: 949px;
$grid-cols-1fr-minmax-maxcontent-2fr: 1fr minmax(min-content, 2fr);

@supports (display: grid) {
	.grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
		grid-column-gap: 1rem;
		row-gap: 1rem;
		margin: 0;
		padding: 0;

		> .grid-item {
			@include stretch-content;

			// used to show decorative images that take up a grid space when available
			&.is-decorative:last-child:nth-child(2n) {
				display: none !important;

				@media only screen and (min-width: $grid-is-3-tablet-min-width) and (max-width: $grid-is-3-tablet-max-width) {
					display: flex !important;
				}
			}
		}

		&.is-horizontal {
			grid-template-columns: none;
			column-gap: 0;
			row-gap: 0.5rem;
		}

		&.is-2 {
			&.is-mobile {
				grid-template-columns: 1fr 1fr !important;
			}

			@include tablet {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&.is-2-desktop {
			@include desktop {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&.is-3 {
			@include tablet {
				grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
			}
			&.is-5-items {
				@include desktop {
					//center last 2 items
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;

					.grid-item {
						flex: 0 1 calc((100% - 2rem) / 3);
					}
				}
			}
		}

		&.is-3-desktop {
			@include desktop {
				grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
			}
		}

		&.is-4 {
			@include tablet {
				grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
			}
			&.is-7-items {
				@include desktop {
					//center last 3 items
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;

					.grid-item {
						flex: 0 1 calc((100% - 3rem) / 4);
					}
				}
			}
		}

		&.is-5-desktop {
			@include desktop {
				grid-template-columns: repeat(5, 1fr);
			}
		}

		&:not(.is-horizontal):not(.is-2):not(.single-item-stretch) {
			> .grid-item:only-child {
				max-width: 308px;
			}
		}

		&.has-min-width-170-tablet-only {
			@include tablet-only {
				grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
			}
		}

		&.is-fibonacci {
			// 1 mobile, 2 tablet, 4 desktop
			grid-template-columns: 1fr;

			@include tablet {
				grid-template-columns: repeat(2, 1fr);
			}

			@include desktop {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}

	.is-small {
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
	}

	.is-center {
		place-items: center;
	}
}

.grid-cols-1fr_minmax_max-content_2fr {
	grid-template-columns: $grid-cols-1fr-minmax-maxcontent-2fr;
}
