// The following classes should be removed, but we'll need solutions / code re-writes before we can.

.button {
	&.is-fullwidth-mobile {
		@include discouraged-mobile {
			display: flex;
			width: 100%;
		}
	}
}
