@use 'sass:map';

$printDisplays: 'block', 'flex', 'none';
$displayPositions: 'fixed', 'absolute';
$paper-size: (
	'a4': (
		'short-side': 210mm,
		'long-side': 297mm
	)
);

@include print {
	@each $item in $printDisplays {
		.display-#{$item}-print {
			display: #{$item} !important; // overrides atlas display
		}
	}
	@each $item in $displayPositions {
		.position-#{$item}-print {
			position: #{$item} !important; // overrides atlas position
		}
	}
	@each $type, $dimensions in $paper-size {
		.size-#{$type}-print-landscape {
			height: map.get($dimensions, 'short-side');
			width: map.get($dimensions, 'long-side');
		}
	}
	body {
		min-height: initial !important;
	}
	.is-fullscreen-print-landscape {
		height: 100vmin;
		width: 100vmax !important; // overrides _container.scss
		max-width: 100vmax !important; // overrides _container.scss
	}
	.is-fullwidth-print {
		width: 100% !important;
	}
	.color-adjust-exact {
		color-adjust: exact;
	}
	.padding-xxl-print {
		padding: 6rem !important; // overrides atlas spacing
	}
	@media (min-height: 99vmax) {
		// https://bugs.chromium.org/p/chromium/issues/detail?id=933350&q=print%20letter%20portrait&can=2 - after bug resolved, update media query to (orientation: portrait)
		// @include orientation-portrait limited to screens in atlas
		.rotate-270-print-portrait {
			transform: rotate(270deg) translate(-100%, 0);
			transform-origin: 0 0;
		}
	}
	.break-inside-avoid {
		break-inside: avoid;
	}
	.table-wrapper {
		overflow: visible !important;
	}
	a[role='tab'][aria-selected='false'] {
		display: none !important;
	}
	a[role='tab'][aria-selected='true'] {
		display: block !important;
	}
	div.embeddedvideo:before {
		display: none !important;
	}
	.heading-anchor {
		margin-#{$content-left}: 0 !important;
		padding-#{$content-left}: 0 !important;
	}
	.primary-holder {
		padding-bottom: 0 !important;
	}
	[data-main-column] {
		//shrink the margin top when print, currently it is fine on conceptual layout
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	//ensure point showing in achievement certificate metadata
	#achievement-certificate
		.metadata.page-metadata
		> li:not(:last-of-type):not(:only-of-type):after {
		content: '•' !important;
		padding-left: 5px;
		padding-right: 5px;
	}
	//hide the point before the contributors
	.metadata.page-metadata > li:nth-last-of-type(2):after {
		content: none !important;
	}
	// qna search form
	.autocomplete-qna-hero {
		display: none !important;
	}
	// Hub product-directory
	#hub-facet-list {
		display: none !important;
	}
	// even we already added display-none-print in the template, we do not want to rebuild all to apply the template
	// remove this after https://dev.azure.com/ceapex/Engineering/_workitems/edit/782967/
	#user-feedback,
	.doc-outline,
	.contributors-holder {
		display: none !important;
	}
}
