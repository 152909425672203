.is-disappearing {
	animation: slideUpOutFadeOut $input-transition-duration $input-timing-function forwards;
}

.is-sliding-up {
	animation: slideUp $input-transition-duration $input-timing-function forwards;
}

.has-animation-none {
	animation: none;
}
